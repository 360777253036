<template>
  <article
    class="no-print grid sm:grid-cols-2 md:grid-cols-4 bg-white overflow-hidden shadow rounded-lg divide-x divide-gray-200"
  >
    <div class="px-5 py-3">
      <div class="flex items-start h-full">
        <div class="flex-shrink-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 text-gray-400 mt-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div class="ml-5 w-0 flex-1 justify-start h-full">
          <dl>
            <dt class="text-sm font-medium text-gray-500 truncate">Line</dt>
            <dd>
              <div class="text-lg font-medium text-gray-900">{{line}}</div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="px-5 py-3">
      <div class="flex items-start h-full">
        <div class="flex-shrink-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 text-gray-400 mt-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
            />
          </svg>
        </div>
        <div class="ml-5 w-0 flex-1 justify-start h-full">
          <dl>
            <dt class="text-sm font-medium text-gray-500 truncate">Station</dt>
            <dd>
              <div class="text-lg font-medium text-gray-900">{{station}}</div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="px-5 py-3">
      <div class="flex items-start h-full">
        <div class="flex-shrink-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 text-gray-400 mt-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div class="ml-5 w-0 flex-1 justify-start h-full">
          <dl>
            <dt class="text-sm font-medium text-gray-500 truncate">Shift</dt>
            <dd>
              <div class="text-lg font-medium text-gray-900">{{shift ? shift : "NIL"}}</div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="px-5 py-3">
      <div class="flex items-start h-full">
        <div class="flex-shrink-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 text-gray-400 mt-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div class="ml-5 w-0 flex-1 justify-start h-full">
          <dl>
            <dt class="text-sm font-medium text-gray-500 truncate">Date</dt>
            <dd>
              <div class="text-lg font-medium text-gray-900">{{shiftPlanDate ? shiftPlanDate : "NIL"}}</div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </article>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      'line': 'auth/userLineName',
      'userStation': 'auth/userStationName',
      'shiftStation': 'currentRoster/currentStationName',
      'shift': 'currentRoster/currentRosterShift',
      'shiftPlanDate': 'currentRoster/currentRosterPlanDateForDisplay',

    }),
    station() {
      return this.shiftStation || this.userStation;
    }
  },
  // beforeUnmount() {
  //   // prevent memory leak
  //   clearInterval(this.interval)
  // },
  // mounted() {
  //   // update the time every second
  //   this.interval = setInterval(() => {
  //     this.time = moment().format('HH:mm');
  //     this.date = moment().format('DD MMM YYYY');
  //   }, 1000)
  // }
}
</script>